/* DON'T EDIT THIS FILE: edit original and run build again */ import { CountryDropdown } from "../../credit/ui/form/country-dropdown.tsx";
import { Labeled } from "../../credit/ui/form/labeled.tsx";
import { TextInput } from "../../credit/ui/form/text-input.tsx";
import { RequiredValidationFeedback } from "../../credit/ui/form/validation-feedback.tsx";
import { getCountryOptions } from "../../framework/country/countries.ts";
import { argentinaCountryCode } from "../../framework/country/country-codes.ts";
import deepEqual from "fast-deep-equal";
import React, { useEffect, useState } from "react";

let countryOptionsWithoutArgentina: Record<string, string>;

export const RequiredInputBorder = ({
  showRequired,
  input,
}: {
  showRequired: boolean;
  input: JSX.Element;
}) => {
  return (
    <>
      {input}
      {showRequired ? <RequiredValidationFeedback /> : null}
    </>
  );
};

const getCountryOptionsWithoutArgentina = () => {
  if (!countryOptionsWithoutArgentina) {
    countryOptionsWithoutArgentina = { ...getCountryOptions() };
    delete countryOptionsWithoutArgentina[argentinaCountryCode];
  }
  return countryOptionsWithoutArgentina;
};

const CountryInputWithLabel = ({
  value,
  setValue,
  showErrors,
}: {
  value?: string;
  setValue: (value: string) => void;
  showErrors: boolean;
}) => (
  <Labeled
    label="País de expedición"
    input={
      <RequiredInputBorder
        showRequired={showErrors && !value}
        input={
          <CountryDropdown
            options={getCountryOptionsWithoutArgentina()}
            className={showErrors && !value ? "is-invalid " : ""}
            value={value ?? ""}
            setValue={setValue}
          />
        }
      />
    }
  />
);

const IdInputWithLabel = ({
  value,
  setValue,
  showErrors,
  afterIdNumber,
  onBlur,
  onKeyPress,
}: {
  value?: string;
  setValue: (value: string) => void;
  showErrors: boolean;
  afterIdNumber: JSX.Element;
  onBlur: (event: React.FocusEvent) => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
}) => (
  <Labeled
    label="Número de Identificación"
    input={
      <div className="d-flex align-items-center justify-content-between">
        <RequiredInputBorder
          showRequired={showErrors && !value}
          input={
            <TextInput
              className={showErrors && !value ? "is-invalid " : ""}
              value={value ?? ""}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              setValue={setValue}
            />
          }
        />
        {afterIdNumber}
      </div>
    }
  />
);

type IdSectionValue = {
  foreignIdNumber: string;
  country: string;
};

export const IdSection = ({
  value,
  setValue,
  showErrors,
  afterIdNumber,
}: {
  value: Partial<IdSectionValue>;
  setValue: ({ country, foreignIdNumber }: IdSectionValue) => Promise<void>;
  showErrors: boolean;
  afterIdNumber: JSX.Element;
}) => {
  const [oldValue, setOldValue] = useState(value);
  const [tempValue, setTempValue] = useState(value);

  const isValid = (
    someValue: Partial<IdSectionValue>
  ): someValue is IdSectionValue =>
    !!someValue.country && !!someValue.foreignIdNumber;

  const setValueSomewhere = (newValue: Partial<IdSectionValue>) => {
    if (isValid(newValue)) {
      setValue(newValue);
    } else {
      setTempValue(newValue);
    }
  };

  useEffect(() => {
    if (!deepEqual(value, oldValue)) {
      setTempValue(value);
      setOldValue(value);
    }
  }, [value, oldValue]);

  const maybePropagateValue = () => {
    if (isValid(tempValue) && !deepEqual(tempValue, value)) {
      setValue(tempValue);
    }
  };

  return (
    <div>
      <CountryInputWithLabel
        value={tempValue.country}
        setValue={(newCountry) => {
          setValueSomewhere({ ...tempValue, country: newCountry });
        }}
        showErrors={showErrors}
      />
      <IdInputWithLabel
        value={tempValue.foreignIdNumber}
        setValue={(newForeignIdNumber) =>
          setTempValue({ ...tempValue, foreignIdNumber: newForeignIdNumber })
        }
        onBlur={maybePropagateValue}
        onKeyPress={(event) => {
          if (event.charCode === 13) {
            maybePropagateValue();
          }
        }}
        showErrors={showErrors}
        afterIdNumber={afterIdNumber}
      />
    </div>
  );
};
